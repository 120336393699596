.header {
    background: #fff;
    border-bottom: 1px solid #fff;
    position: fixed;
    z-index: 1;
    width: 100%;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
}

.layoutHeader {
    padding: 0 !important;
    text-align: right;
}

.inline {
    display: inline;
}

.ul {
    list-style: none;
    padding: 0;
    width: 300px;
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {

    .container {
        padding: 0 5em;
    }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {


}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .layout {
        margin-left: 0;
    }
    .header {
        padding: 0;
    }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px) {
    .layoutHeader {
        padding: 0 15px;
    }
    .header {
        padding: 0;
    }
}
/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
    .layoutHeader {
        padding: 0 15px !important;
    }
    .header {
        padding: 0;
    }
}

