/* import font family */
@font-face {
    font-family: 'quicksands';
    src: url('../fonts/Quicksand-VariableFont_wght.ttf') format('truetype');
}

html {
    scroll-behavior: smooth;
}

/* set font */
body {
    font-family: quicksands, sans-serif !important;
}

/* sider menu item icon & text */
.ant-menu-item > a {
    color: #ffffff !important;
}
.ant-menu-submenu > .ant-menu {
    background: transparent;
}
.ant-menu-item-selected {

    background: transparent;
}
.ant-menu-inline {
    border-right: none !important;
}
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    color: #ffffff !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: rgba(233, 230, 230, 0.68) !important;
    border-radius: 10px !important;
}
.ant-menu-inline .ant-menu-item::after {
    position: absolute;
    right: 0;
    border-right: 3px solid transparent !important;
}
.ant-menu-inline .ant-menu-item {
    width: 95% !important;
}
.ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
    background: #fff !important;
}
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
    color: white !important;
}

/* Header menu */
.ant-menu-item > a.front-header {
    color: #000000 !important;
    font-weight: bold;
}

.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-item > a.front-header:hover {
    color : #1890ff !important;
    border: none !important;
}
.ant-menu-item > a.front-header-2:hover {
    color : red !important;
    border: none !important;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
    border-bottom: none !important;
}
.ant-menu-submenu > .ant-menu-submenu-inline > .styles_submenu__FG742 {
    color: #000000 !important;
}

/* form item  */
.ant-form-item {
    margin-bottom: 10px !important;
}

/* list dashboardHeader */
.ant-list-header, .ant-list-footer {
    padding-bottom: 0 !important;
}

/* pagination */
.ant-table-pagination.ant-pagination {
    margin: 16px 20px !important;
}

/* table */
.ant-table-cell-fix-right-first::after {
    box-shadow: inset -10px 0 8px -8px rgba(0,0,0,.15) !important;
}


/*body > div:nth-child(7) > div > div.ant-modal-wrap.ant-modal-centered > div > div.ant-modal-content > div > form > div:nth-child(3) > div.ant-col.ant-form-item-control > div > div > div > div.fr-wrapper.show-placeholder > div:nth-child(1),*/
/*body > div:nth-child(7) > div > div.ant-modal-wrap.ant-modal-centered > div > div.ant-modal-content > div > form > div:nth-child(3) > div.ant-col.ant-form-item-control > div > div > div > div.fr-wrapper > div:nth-child(1),*/
/*body > div:nth-child(8) > div > div.ant-modal-wrap.ant-modal-centered > div > div.ant-modal-content > div > form > div:nth-child(3) > div.ant-col.ant-form-item-control > div > div > div > div.fr-wrapper.show-placeholder > div:nth-child(1),*/
/*body > div:nth-child(8) > div > div.ant-modal-wrap.ant-modal-centered > div > div.ant-modal-content > div > form > div:nth-child(3) > div.ant-col.ant-form-item-control > div > div > div > div.fr-wrapper > div:nth-child(1),*/
/*body > div:nth-child(8) > div > div.ant-modal-wrap.ant-modal-centered > div > div.ant-modal-content > div > form > div:nth-child(4) > div.ant-col.ant-form-item-control > div > div > div > div.fr-wrapper.show-placeholder > div:nth-child(1),*/
/*body > div:nth-child(8) > div > div.ant-modal-wrap.ant-modal-centered > div > div.ant-modal-content > div > form > div:nth-child(5) > div.ant-col.ant-form-item-control > div > div > div > div.fr-wrapper.show-placeholder > div:nth-child(1),*/
/*body > div:nth-child(8) > div > div.ant-modal-wrap.ant-modal-centered > div > div.ant-modal-content > div > form > div:nth-child(10) > div.ant-col.ant-form-item-control > div > div > div > div.fr-wrapper.show-placeholder > div:nth-child(1),*/
/*body > div:nth-child(8) > div > div.ant-modal-wrap.ant-modal-centered > div > div.ant-modal-content > div > form > div:nth-child(4) > div.ant-col.ant-form-item-control > div > div > div > div.fr-wrapper > div:nth-child(1),*/
/*body > div:nth-child(8) > div > div.ant-modal-wrap.ant-modal-centered > div > div.ant-modal-content > div > form > div:nth-child(5) > div.ant-col.ant-form-item-control > div > div > div > div.fr-wrapper > div:nth-child(1),*/
/*body > div:nth-child(8) > div > div.ant-modal-wrap.ant-modal-centered > div > div.ant-modal-content > div > form > div:nth-child(10) > div.ant-col.ant-form-item-control > div > div > div > div.fr-wrapper > div:nth-child(1),*/
/*body > div:nth-child(8) > div > div.ant-modal-wrap.ant-modal-centered > div > div.ant-modal-content > div > form > div:nth-child(7) > div.ant-col.ant-form-item-control > div > div > div > div.fr-wrapper.show-placeholder > div:nth-child(1),*/
/*body > div:nth-child(8) > div > div.ant-modal-wrap.ant-modal-centered > div > div.ant-modal-content > div > form > div:nth-child(7) > div.ant-col.ant-form-item-control > div > div > div > div.fr-wrapper > div:nth-child(1),*/
/*#root > section > section > main > div.ant-spin-nested-loading > div > div > form > div:nth-child(2) > div.ant-col.ant-form-item-control > div > div > div > div.fr-wrapper > div:nth-child(1){*/
/*    display: none !important;*/
/*}*/